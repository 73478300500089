import React from "react"

import Layout from "../components/Layout"
import SEO from "@/components/SEO"
import { StaticImage } from "gatsby-plugin-image"

type PageProps = {
  data: any
}

const TaxEfficientGiving: React.FC<PageProps> = ({}) => (
  <Layout
    sectionOne={
      <>
        <StaticImage
          src="../../static/images/2018/03/tax-eff.jpg"
          className="absolute inset-0 -z-[1]"
          alt="Tax Efficient Giving"
        />
        <div className="container text-white pt-96 pb-4 flex flex-col items-center text-center">
          <h1 className="pb-2 md:pb-8 responsive-heading uppercase">
            Tax Efficient Giving
          </h1>
          <div className="pt-8 flex justify-center">
            <img src="/images/down.png" alt="Down arrow" />
          </div>
        </div>
      </>
    }
  >
    <SEO title="Tax Efficient Giving" />
    <div className="container py-14">
      <div className="flex justify-center">
        <div className="flex flex-col gap-4 max-w-[700px]">
          <h3 className="mb-4 text-3xl uppercase">
            Gifts from UK tax payers are eligible for Gift Aid. Higher rate tax
            payers can reclaim tax against their giving, as illustrated below.
            Please contact the RBAI Foundation Director for further information.
          </h3>

          <img
            src="/images/2018/03/tax-efficient-giving.png"
            srcSet="/images/2018/03/tax-efficient-giving.png 2136w, /images/2018/03/tax-efficient-giving-300x126.png 300w, /images/2018/03/tax-efficient-giving-768x324.png 768w, /images/2018/03/tax-efficient-giving-1024x431.png 1024w"
            sizes="(max-width: 2136px) 100vw, 2136px"
            style={{ width: "100%" }}
          />

          <p className="mt-5">
            <strong>INTERNATIONAL DONORS</strong>
          </p>
          <p>
            Donors in the USA can give tax efficiently via the British Schools
            and Universities Foundation (www.bsuf.org), ensuring they receive a
            501c receipt, whilst 100% of the gift reaches RBAI.
          </p>
          <p>
            Donors in other countries should contact the RBAI Foundation
            Director to discussed tax-efficient contributions.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>DONATING SHARES OR OTHER ASSETS</strong>
          </p>
          <p>
            RBAI can accept gifts of shares or assets including land.  Such
            gifts from UK tax payers will be exempt from Capital Gains Tax,
            whilst the donor can also claim Income Tax relief on the market
            value of qualifying shares at the point of gift.
          </p>
          <p>
            <span>
              By way of example, a 45% taxpayer gifts shares worth £10,000 to
              RBAI, which they purchased for £6,000.  Alongside saving 20%
              capital gains tax on the profit of £4,000, they can claim 45% tax
              relief on the total gift.  This equates to a total tax saving of
              £5,300, meaning the net cost of the gift to the donor is £4,700. 
              Source: <i>Charities Aid Foundation</i>
            </span>
            <span></span>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default TaxEfficientGiving
